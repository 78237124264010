<template>
    <div class="options-input colorpicker" @click.stop="" :class="{'is-disabled': disabled}">
        <input type="text" placeholder="Votre pantone" @focus="focused = true" v-model="search">
        <div class="colorpicker-current" @click="focused = true">
            <ColorPickerItem :color="value" />
        </div>
        <transition name="opacity">
            <div class="colorpicker-library" v-if="openLibrary && !disabled" ref="libraryEl">
                <div class="colorpicker-colors" v-if="!colorsPreset">
                    <div class="colorpicker-colors-title">
                        {{ $t('colorpicker.library') }}
                    </div>
                    <div class="colorpicker-colors-line" v-for="(line, index) in library" :key="index">
                        <ColorPickerItem
                            :color="color.hex" v-for="color in line" :key="color.hex"
                            @selected="selectColor(color)" :label="color.pantone" />
                    </div>
                </div>
                <div class="colorpicker-colors" v-if="searchedColors.length && !colorsPreset">
                    <div class="colorpicker-colors-title">
                        {{ $t('colorpicker.searched') }}
                    </div>
                    <div class="colorpicker-colors-searched">
                        <ColorPickerItem
                            :color="color.hex" v-for="color in searchedColors" :key="color.hex"
                            @selected="selectColor(color)" :label="color.pantone" />
                    </div>
                </div>
                <div class="colorpicker-colors" v-if="!!colorsPreset">
                    <div class="colorpicker-colors-title">
                        {{ $t('colorpicker.preset') }}
                    </div>
                    <div class="colorpicker-colors-line">
                        <ColorPickerItem
                            :color="color" v-for="color in colorsPreset" :key="color"
                            @selected="selectColor({hex: color})" label="" />
                    </div>
                </div>
            </div>
        </transition>
        <ColorPickerResults :search="search" v-if="search && !disabled" @selected="selectColor($event, true)" />
        <div class="colorpicker-tooltip" v-if="disabled">
            {{ $t('colorpicker.disabled') }}
        </div>
    </div>
</template>

<script>
    import ColorPickerItem from './ColorPickerItem'
    import ColorPickerResults from './ColorPickerResults'
    import {mapGetters} from 'vuex'

    export default {
        name: 'ColorPicker',
        components: {ColorPickerResults, ColorPickerItem},
        props: {
            value: String,
            disabled: Boolean,
            colorsPreset: Array
        },
        data: () => ({
            focused: false,
            search: '',
            library: [
                [
                    {
                        pantone: 'Yellow C',
                        hex: '#fedd00'
                    },
                    {
                        pantone: '123 C',
                        hex: '#ffc72c'
                    },
                    {
                        pantone: 'Orange 021 C',
                        hex: '#fe5000'
                    },
                    {
                        pantone: '1665 C',
                        hex: '#dc4405'
                    },
                    {
                        pantone: '471 C',
                        hex: '#b86125'
                    },
                    {
                        pantone: '477 C',
                        hex: '#623b2a'
                    }
                ],
                [
                    {
                        pantone: '182 C',
                        hex: '#fabbcb'
                    },
                    {
                        pantone: 'Violet C',
                        hex: '#440099'
                    },
                    {
                        pantone: 'Rubine red C',
                        hex: '#ce0058'
                    },
                    {
                        pantone: '185 C',
                        hex: '#e4002b'
                    },
                    {
                        pantone: '485 C',
                        hex: '#da291c'
                    },
                    {
                        pantone: 'Purple C',
                        hex: '#bb29bb'
                    }
                ],
                [
                    {
                        pantone: '382 C',
                        hex: '#c4d600'
                    },
                    {
                        pantone: 'Green C',
                        hex: '#00ab84'
                    },
                    {
                        pantone: '347 C',
                        hex: '#009a44'
                    },
                    {
                        pantone: '336 C',
                        hex: '#00664f'
                    },
                    {
                        pantone: '3435 C',
                        hex: '#154734'
                    }
                ],
                [
                    {
                        pantone: '292 C',
                        hex: '#69b3e7'
                    },
                    {
                        pantone: 'Process blue C',
                        hex: '#0085ca'
                    },
                    {
                        pantone: '286 C',
                        hex: '#0033a0'
                    },
                    {
                        pantone: 'Blue 072 C',
                        hex: '#10069f'
                    },
                    {
                        pantone: 'Violet C',
                        hex: '#440099'
                    },
                    {
                        pantone: '276 C',
                        hex: '#221c35'
                    }
                ],
                [
                    {
                        pantone: '420 C',
                        hex: '#c7c9c7'
                    },
                    {
                        pantone: '429 C',
                        hex: '#a2aaad'
                    },
                    {
                        pantone: '430 C',
                        hex: '#7c878e'
                    },
                    {
                        pantone: '432 C',
                        hex: '#333f48'
                    },
                    {
                        pantone: '433 C',
                        hex: '#1d252d'
                    }
                ],
                [
                    {
                        pantone: 'Blanc',
                        hex: '#ffffff'
                    },
                    {
                        pantone: 'Noir',
                        hex: '#000000'
                    },
                    {
                        pantone: 'Argent',
                        hex: '#a8a9ad'
                    },
                    {
                        pantone: 'Or',
                        hex: '#a29160'
                    }
                ],
            ]
        }),
        methods: {
            selectColor(color, searched = false) {
                if(searched) {
                    // add to searched color list
                    this.$store.commit('library/addSearchedColor', color)
                }
                this.$emit('input', color.hex)
                this.$emit('change', color.hex)
            },
            onBodyClick() {
                if(!this.focused) return
                this.focused = false
                this.search = ''
            }
        },
        watch: {
            openLibrary(newVal) {
                if(newVal) {
                    if(window.openedColorPicker) {
                        window.openedColorPicker.onBodyClick()
                    }
                    window.openedColorPicker = this
                    // check if library isnt out of screen
                    if(this.$refs.libraryEl) {
                        this.$refs.libraryEl.style.transform = 'none'
                    }
                    this.$nextTick(() => {
                        const bounds = this.$refs.libraryEl.getBoundingClientRect()
                        if(bounds.right > window.innerWidth) {
                            this.$refs.libraryEl.style.transform = 'translate(' + (window.innerWidth - bounds.right - 10) + 'px, 0)'
                        }
                        if(bounds.left < 0) {
                            this.$refs.libraryEl.style.transform = 'translate(' + (-bounds.left + 10) + 'px, 0)'
                        }
                    })
                } else {
                    window.openedColorPicker = null
                }
            }
        },
        computed: {
            ...mapGetters({
                searchedColors: 'library/searchedColors'
            }),
            openLibrary() {
                return this.focused && !this.search
            }
        },
        mounted() {
            document.body.addEventListener('click', this.onBodyClick)
        },
        beforeDestroy() {
            document.body.removeEventListener('click', this.onBodyClick)
        }
    }
</script>
