import Vue from 'vue'
import Vuex from 'vuex'
import marquage from './modules/marquage'
import editor from './modules/editor'
import variants from './modules/variants'
import product from './modules/product'
import history from './modules/history'
import library from './modules/library'
import notification from './modules/notification'
import cart from './modules/cart'
import popup from './modules/popup'
import clipart from './modules/clipart'
import axios from 'axios'
import Constants from '../config/Constants'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        sidebarToggleOpened: '',
        sidebarDesktopToggled: false,
        sidebarActive: false,
        sidebarLeftActive: false,
        sessionId: ''
    },
    mutations: {
        setSidebarToggle: (state, slug) => {
            state.sidebarToggleOpened = slug
        },
        toggleSidebar: (state) => {
            state.sidebarActive = !state.sidebarActive
        },
        toggleSidebarLeft: (state) => {
            state.sidebarLeftActive = !state.sidebarLeftActive
        },
        toggleSidebarDesktop: (state) => {
            state.sidebarDesktopToggled = !state.sidebarDesktopToggled
        },
        setSessionId: (state, sessionId) => {
            state.sessionId = sessionId
        },
    },
    getters: {
        sessionId: state => {
            return state.sessionId
        },
    },
    actions: {
        /**
         * get product customization info from api, to init all stores
         * @param commit
         * @param ref
         * @returns {Promise<void>}
         */
        getProduct({commit}, {ref = null, shareId = null, idProduct = null}) {
            let request
            if (ref) {
                request = axios.post(process.env.VUE_APP_CONFIGURATEUR_API_URL + 'configurateur/getByRef', {
                    articleCode: ref,
                })
            } else {
                request = axios.get(process.env.VUE_APP_API_URL + 'save/' + shareId)
            }

            return request.then((response) => {
                // console.log(response.data)

                // format marquages infos
                let marquages = {}
                let marquageSelected = response.data.markings.length ? response.data.markings[0].id.toString() : ''
                let editorItems = []
                if (response.data.markings) {
                    for (const marking of response.data.markings) {
                        const editorItemsMarking = []
                        if (!marking.config.markingTypeLabel) {
                            marking.config.markingTypeLabel = ''
                            marking.config.markingTypeVariantLabel = ''
                        }

                        // get existing texts
                        if (marking.config.texts) {
                            for (const t of marking.config.texts) {
                                const item = {
                                    ...t,
                                }
                                item.type = Constants.EDITOR.TYPE_TEXT
                                editorItemsMarking.push(item)
                            }
                        }
                        // get existing images
                        if (marking.config.images) {
                            for (const t of marking.config.images) {
                                const item = {
                                    ...t,
                                }
                                item.type = Constants.EDITOR.TYPE_IMAGE
                                editorItemsMarking.push(item)
                            }
                        }
                        // get existing cliparts
                        if (marking.config.cliparts) {
                            for (const t of marking.config.cliparts) {
                                const item = {
                                    ...t,
                                }
                                item.type = Constants.EDITOR.TYPE_CLIPART
                                editorItemsMarking.push(item)
                            }
                        }
                        // global items config
                        for (const item of editorItemsMarking) {
                            item.marquage = marking.id
                            item.selected = false
                            item.added = false
                        }
                        editorItems = [
                            ...editorItems,
                            ...editorItemsMarking
                        ]
                        // select default marking, technique and variant
                        marking.types = marking.types.filter(item => item.variants !== null) // remove types with no variants
                        for (const t of marking.types) {
                            for (const v of t.variants) {
                                if (v.isDefault) {
                                    marquageSelected = marking.id.toString()
                                    marking.config.markingTypeLabel = t.label
                                    marking.config.markingTypeVariantLabel = v.label
                                    marking.config.markingTypeVariantId = v.id
                                }
                            }
                        }

                        // adding a technique for testing purpose
                        //marking.types.push({"label":"Tampographie","variants":[{"label":"Tampo","id":"1333","technicalId":"OOP","laserColor":null,"nbColorIncluded":1,"nbColorMax":0,"isDefault":false,"startX":687,"startY":793,"endX":1266,"endY":826,"maxX":1600,"maxY":1600,"width":579,"height":33,"diameter":0,"urlIcon":"http://dimo-api.preprod.ikuzo.fr/cache/OTk0X1oxOTM2.png","heightCm":0.4,"widthCm":7}]})

                        if(!marquages[marking.id]) {
                            marquages[marking.id] = marking
                        }
                    }
                }
                // console.log(marquages)
                // console.log(marquageSelected)

                // format variants infos
                let variants = {}
                if (response.data.variants) {
                    let first = true
                    for (const variant of response.data.variants) {
                        const groupId = variant.group.id.toString()
                        if (!variants[groupId]) {
                            variants[groupId] = {
                                label: variant.group.label,
                                selected: (response.data.selectedVariants && response.data.selectedVariants[groupId]) ? response.data.selectedVariants[groupId] : variant.id,
                                options: {},
                            }
                            if(variants[groupId].selected && variant.hasSubVariants && variant.subVariants && variant.subVariants.length) {
                                commit('variants/selectSubvariant', { subvariantId: variant.subVariants[0].id } )
                            }
                        }

                        // variant.config.quantity = 0

                        delete variant.group
                        variants[groupId].options[variant.id] = variant
                        first = false
                    }
                }

                if(!response.data.idProduct) {
                    response.data.idProduct = idProduct
                }
                commit('product/init', response.data)

                // selected marking in config ? override default
                if(response.data.selectedMarking) {
                    marquageSelected = response.data.selectedMarking
                }

                commit('marquage/init', {
                    items: marquages,
                    selected: marquageSelected,
                })

                commit('variants/init', {
                    items: variants,
                })

                commit('editor/initItems', {
                    items: editorItems,
                })

                // console.log(state, data)
            })
        },
        /**
         * get object containing all customization data
         * @param state
         * @returns {{markings: Array, variants: Array}}
         */
        getCustomization: ({state, getters}) => {
            return new Promise((resolve) => {

                let markings = []
                let variants = []

                for (let markingStore of Object.values(state.marquage.items)) {
                    let marking = {
                        ...markingStore
                    }

                    // get all custom elements for this marking, to pass to config
                    const markingItems = getters['editor/itemsByMarquage']({
                        marquage: markingStore.id,
                    })
                    marking.config.texts = []
                    marking.config.images = []
                    marking.config.cliparts = []
                    for (const mt of Object.values(markingItems)) {
                        let mtClone = {...mt}
                        delete mtClone.marquage
                        delete mtClone.type
                        delete mtClone.selected
                        delete mtClone.added
                        switch (mt.type) {
                            case Constants.EDITOR.TYPE_TEXT:
                                marking.config.texts.push(mtClone)
                                break
                            case Constants.EDITOR.TYPE_IMAGE:
                                marking.config.images.push(mtClone)
                                break
                            case Constants.EDITOR.TYPE_CLIPART:
                                marking.config.cliparts.push(mtClone)
                                break
                        }
                    }

                    markings.push(marking)
                }

                // format variants infos
                let selectedVariants = {}
                for (let [variantId, variantStore] of Object.entries(state.variants.variants)) {
                    for (let optionStore of Object.values(variantStore.options)) {
                        let variant = {
                            ...optionStore,
                            group: {
                                id: parseInt(variantId),
                                label: variantStore.label,
                            },
                        }
                        variants.push(variant)

                        if(variantStore.selected) {
                            selectedVariants[variantId] = variantStore.selected
                        }
                    }
                }

                resolve({
                    ...state.product,
                    markings,
                    variants,
                    selectedMarking: state.marquage.selected,
                    selectedVariants: selectedVariants
                })
            })
        },
        generatePreview({dispatch}, { annotate }) {
            return new Promise(async (resolve, reject) => {
                const custom = await dispatch('getCustomization')
                axios.post(process.env.VUE_APP_API_URL + 'save', custom, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                     .then((response) => {
                         if (response.data && response.data.success) {
                             axios.post(process.env.VUE_APP_API_URL + 'preview', {
                                 code: response.data.id,
                                 annotate: annotate
                             }, {
                                 responseType: 'arraybuffer',
                             })
                                  .then((response) => {
                                      this.imgBlob = btoa(
                                          new Uint8Array(response.data)
                                              .reduce((data, byte) => data + String.fromCharCode(byte), ''),
                                      )
                                      resolve(
                                          `data:${response.headers['content-type'].toLowerCase()};base64,${this.imgBlob}`)
                                  })
                                  .catch((error) => {
                                      reject(error)
                                  })
                         }
                     })
                     .catch((error) => {
                         reject(error)
                     })

            })
        },
    },
    modules: {
        marquage,
        editor,
        variants,
        product,
        cart,
        history,
        library,
        notification,
        popup,
        clipart,
    },
})
