<template>
    <div class="cart">
        <div class="cart-prices">
            <div class="cart-prices-body" :class="{ 'is-loading': loading }">
                <template v-for="(variant, slug) in variants">
                    <CartItemVariant
                        v-for="(option, optionSlug) in variant.options"
                        :key="optionSlug"
                        :option="option"
                        v-if="option.config.quantity > 0"
                        @delete="deleteVariant(slug, optionSlug)"
                        :is-enabled="variantEnabled[option.id]"
                        :qty-error="quantitiesErrors.options[option.id]"
                    />
                </template>
                <template v-for="(marquage, marquageSlug) in marquages">
                    <template
                        v-for="(technique, techniqueIndex) in marquage.types"
                    >
                        <CartItemTechnique
                            v-for="variant in technique.variants"
                            :key="variant.id"
                            :variant="variant"
                            :technique="technique"
                            :marking="marquage"
                            :show-price="!quantitiesErrors.total && !loading"
                            v-if="marquage.config.markingTypeVariantId === variant.id"
                            @delete="deleteTechnique(marquageSlug)"
                        />
                    </template>
                </template>
                <div class="cartitem" v-for="priceFrais in pricesFrais">
                    <div class="cartitem-body">
                        <div class="cartitem-thumb"></div>
                        <div class="cartitem-name">
                            <b>{{ priceFrais.label }}</b>
                        </div>
                        <div class="cartitem-price">
                            {{ priceFrais.total | price }} HT
                        </div>
                    </div>
                </div>
                <div class="cartitem" v-if="quantitiesErrors.total" style="padding: 10px 0;">
                    <div class="cartitem-message is-warning" style="font-size: 12px;">
                        <svg-sprite icon="info-rouge" width="24" height="24" />
                        <span>La quantité minimale requise pour passer la commande est de {{ qtyMin }}</span>
                    </div>
                </div>
                <div class="cartitem" v-if="quantitiesErrors.gamme" style="padding: 10px 0;">
                    <div class="cartitem-message is-warning" style="font-size: 12px;">
                        <svg-sprite icon="info-rouge" width="24" height="24" />
                        <span>La quantité minimum pour panacher doit être de {{ qtyMinGamme }} produits</span>
                    </div>
                </div>
                <div class="cartitem" v-if="quantitiesErrors.steps" style="padding: 10px 0;">
                    <div class="cartitem-message is-warning" style="font-size: 12px;">
                        <svg-sprite icon="info-rouge" width="24" height="24" />
                        <span>Commande possible par pallier de {{ qtyStep }}, il manque {{ quantitiesErrors.steps }} produit{{ quantitiesErrors.steps > 1 ? 's' : '' }}</span>
                    </div>
                </div>
                <div class="cartitem" v-if="commentsError" style="padding: 10px 0;">
                    <div class="cartitem-message is-warning" style="font-size: 12px;">
                        <svg-sprite icon="info-rouge" width="24" height="24" />
                        <span>Veuillez préciser les informations demandées sur la partie "Commentaires"</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="cart-footer">
            <div class="cart-footer-title">
                <b>Total produits</b>
                tout inclus, {{ totalQuantity }} unités
            </div>
            <div class="cart-footer-prices" @click.prevent="$store.commit('toggleSidebar')">
                <div class="cart-footer-total" v-if="!quantitiesErrors.total && !loading"><b>{{ prices.total | price }}</b> HT</div>
                <div class="cart-footer-total" v-else></div>
                <div class="price" v-if="prices.unit"><span class="price-value-prefix">soit </span> <span class="price-value">{{ prices.unit | price }}</span> / unité</div>
            </div>
        </div>
        <div class="cart-submit">
            <button class="cart-submit-button button is-primary" @click="validateCart" :class="{'is-loading': validateLoading, 'is-disabled': !variantEnabled.all || quantitiesErrors.global || commentsError }">
                Ajouter au panier
            </button>
        </div>
    </div>
</template>

<script>
    // import VueSlider from 'vue-slider-component'
    import { mapState, mapGetters } from 'vuex'
    import Loader from '../ui/Loader'
    import CartTotal from './CartTotal'
    import axios from 'axios'
    import CartItemVariant from "./CartItemVariant.vue";
    import CartItemTechnique from "./CartItemTechnique.vue";
    import screenshots from "../../utils/screenshots";

    export default {
        name: 'Cart',
        components: {
            CartItemTechnique,
            CartItemVariant,
            CartTotal,
            // VueSlider
            Loader
        },
        props: {
            isMobile: Boolean
        },
        data: () => ({
            updatePriceInterval: false,
            loading: false,
            needUpdate: false,
            token: '',
            jsonId: '',
            validateLoading: false
        }),

        mounted() {
            // get cart token in get params
            const urlParams = new URLSearchParams(window.location.search)
            this.token = urlParams.get('token')
        },

        computed: {
            ...mapState({
                marquages: state => state.marquage.items
            }),
            ...mapGetters({
                prices: 'cart/prices',
                variants: 'variants/all',
                editorItems: 'editor/itemsAll',
                markingId: 'marquage/selected',
                idProduct: 'product/idProduct',
                qtyMinProduct: 'product/qtyMin',
                qtyMinGamme: 'product/qtyMinGamme',
                qtyMix: 'product/qtyMix',
                qtyStep: 'product/qtyStep',
                totalQuantity: 'variants/totalQuantity',
                qtyMinMarquages: 'marquage/qtyMinSelectedMarkings'
            }),
            qtyMin() {
                let qtyMin = Math.max(this.qtyMinProduct, this.qtyMinMarquages)
                // arrondi au palier supérieur du produit
                const grilleProduit = this.$store.getters['variants/grilleTarifProduit']
                for(const row of grilleProduit) {
                    const f = parseInt(row.floor)
                    if(qtyMin <= f) {
                        qtyMin = f
                        break
                    }
                }
                return qtyMin
            },
            selectedTechnique() {
                return this.$store.getters['marquage/currentTechnique'](this.markingId)
            },
            // check si les différents variantes choisies sont disponibles pour les techniques de marquage sélectionnées
            variantEnabled() {
                const variantsEnabled = {
                    all: true
                }
                for(const variant of Object.values(this.variants)) {
                    for(const option of Object.values(variant.options)) {
                        if(option.config.quantity) {
                            let enabled = this.$store.getters['marquage/variantEnabled'](option.id)
                            if (enabled && option.subVariants) {
                                for (const subvariant of option.subVariants) {
                                    if (subvariant.config.quantity) {
                                        const subEnabled = this.$store.getters['marquage/variantEnabled'](option.id, subvariant.id)
                                        if (!subEnabled.enabled) {
                                            enabled = subEnabled
                                            break
                                        }
                                    }
                                }
                            }
                            variantsEnabled['' + option.id] = enabled
                            if (!enabled.enabled) {
                                variantsEnabled.all = false
                            }
                        }
                    }
                }
                return variantsEnabled
            },
            // check si les quantités minimales et les paliers sont respectés
            quantitiesErrors() {
                const errors = {
                    options: {},
                    total: false,
                    steps: false,
                    global: false,
                    gamme: false
                }
                let totalQty = 0
                let hasOptionError = false
                for(const variant of Object.values(this.variants)) {
                    for (const option of Object.values(variant.options)) {
                        const qty = option.config.quantity
                        if(qty) {
                            totalQty += qty

                            if(!this.qtyMix) {
                                if(qty < this.qtyMin) {
                                    hasOptionError = true
                                    errors.options['' + option.id] = 'La quantité minimale requise pour passer la commande est de ' + this.qtyMin
                                } else if(this.qtyStep) {
                                    const step = qty % this.qtyStep
                                    if(step !== 0) {
                                        hasOptionError = true
                                        errors.options['' + option.id] = 'Commande possible par pallier de ' + this.qtyStep + ', il manque '+ (this.qtyStep - step)+' produit' + (this.qtyStep - step > 1 ? 's' : '')
                                    }
                                }
                            }
                            if(this.qtyMinGamme && qty < this.qtyMinGamme) {
                                errors.gamme = true
                            }
                        }
                    }
                }
                if(this.qtyMix && totalQty < this.qtyMin) {
                    errors.total = true
                } else if(this.qtyMix && this.qtyStep) {
                    const step = totalQty % this.qtyStep
                    if(step !== 0) {
                        errors.steps = this.qtyStep - step
                    }
                }
                if(errors.total || errors.steps || hasOptionError) {
                    errors.global = true
                }
                return errors
            },
            commentsError() {
                // si il y a une indication spéciale pour le remplissage du champs commentaires, on oblige l'utilisateur à remplir le champ
                const hasCommentHelp = !!this.$store.state.product.comment
                const userComment = this.$store.getters['product/help']
                return hasCommentHelp && (!userComment || userComment.length < 3)
            },
            pricesFrais() {
                const pricesFrais = []
                if(this.prices && this.prices.groups) {
                    for(const group of this.prices.groups) {
                        for(const detail of group.details) {
                            if(!detail.isRawProduct && detail.label !== 'Marquage' && detail.label !== 'Marquage inclus') {
                                pricesFrais.push(detail)
                            }
                        }
                    }
                }
                return pricesFrais
            }
        },
        watch: {
            variants: {
                deep: true,
                handler() {
                    this.priceItemChanged()
                }
            },
            editorItems: {
                deep: true,
                handler() {
                    this.priceItemChanged()
                }
            },
            markingId() {
                this.priceItemChanged()
            },
            'selectedTechnique.id'() {
                this.priceItemChanged()
            }
        },
        methods: {
            /**
             * an information that can change price has been modified, update price not too frequently
             */
            priceItemChanged() {
                this.updatePriceInterval && clearTimeout(this.updatePriceInterval)
                this.updatePriceInterval = setTimeout(() => {
                    if (!this.loading) {
                        this.updatePrice()
                    } else {
                        this.needUpdate = true
                    }
                }, 1000)
            },
            async updatePrice() {
                if (this.isMobile) {
                    return
                }
                this.loading = true
                const custom = await this.$store.dispatch('getCustomization')
                // console.log(custom)
                this.$store.dispatch('cart/updatePrice', custom)
                    .finally(() => {
                        this.loading = false
                        // params have changed during loading, load again
                        if (this.needUpdate) {
                            this.needUpdate = false
                            this.updatePrice()
                        }
                    })
            },
            toggleDetails() {
                this.showDetails = !this.showDetails
            },
            /**
             * validate cart, save current custom json and redirect to shop with correct post params
             * @returns {Promise<void>}
             */
            async validateCart() {
                if (this.validateLoading) {
                    return
                }
                this.validateLoading = true

                const custom = await this.$store.dispatch('getCustomization')
                axios.post(process.env.VUE_APP_API_URL + 'save', custom, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(( response ) => {
                        if (response.data && response.data.success) {
                            this.jsonId = response.data.id
                            screenshots.generateScreenshots((screens) => {
                                if(window.parent) {
                                    window.parent.postMessage({
                                        type: 'configuratorValidate',
                                        custom: custom,
                                        screenshots: screens,
                                        saveId: response.data.id,
                                        prices: this.prices
                                    }, '*')

                                    console.log({
                                        type: 'configuratorValidate',
                                        custom: custom,
                                        screenshots: screens,
                                        saveId: response.data.id,
                                        prices: this.prices
                                    })
                                }
                            })
                        }
                    })
                    .catch(( error ) => {
                        this.validateLoading = false
                    })
            },

            deleteVariant(variantSlug, optionSlug) {
                this.$store.commit('variants/setOptionQuantity', {
                    variantId: variantSlug,
                    optionId: optionSlug,
                    subVariantId: null,
                    quantity: 0
                })
            },

            deleteTechnique(marquageSlug) {
                this.$store.commit('marquage/selectTechnique', {
                    markingId: marquageSlug,
                    techniqueIndex: null,
                    variantId: null
                })
            }
        }
    }
</script>
