<template>
    <drr
        :x="positionZoomed.x" :y="positionZoomed.y" :w="positionZoomed.w" :h="positionZoomed.h"
        :angle="item.rotation"
        :centerBound="item.rotation ? outerBounds : null"
        :outerBound="item.rotation ? null : outerBounds"
        :selected="item.selected"
        :deselectLocked="true"
        :aspect-ratio="true"
        @change="updatePosition"
        @select="onSelect"
        @deselect="onDeselect"
        :custom-class="{ 'is-image': true, 'is-loading': loading }"
    >
        <div class="drr-inner" ref="inner">
            <img :src="item.imageObj.url" alt="" >
        </div>
        <div class="drr-delete" @click.prevent="deleteItem">
            <svg-sprite icon="corbeille-white" width="17" height="17" />
        </div>
    </drr>
</template>

<script>
    import drr from './drr'
    import EditorItem from '../../mixins/EditorItem'

    export default {
        name: 'EditorImage',
        mixins: [EditorItem],
        components: {
            drr
        },
        props: {
            item: Object,
            itemIndex: String,
            dimensions: Object,
            zoom: Number
        },
        data: () => ({
            loading: false
        }),
        mounted() {
            if(this.item.x === null) {
                this._setDefaultPosition()
            }
        },
        computed: {
        },
        watch: {
            'item.options': {
                deep: true,
                handler() {
                    // option changed, create new image
                    this.createImage()
                }
            },
            laserColor() {
                this.createImage()
            }
        },
        methods: {
            /**
             * set default position for the item when we add it, center it on marquage area
             * @private
             */
            _setDefaultPosition() {
                const imgRatio = this.item.imageObj.width / this.item.imageObj.height,
                    marquageRatio = this.dimensions.marquage.width / this.dimensions.marquage.height
                let imgWidth, imgHeight

                if(imgRatio > marquageRatio) {
                    imgWidth = this.dimensions.marquage.width
                    imgHeight = imgWidth / imgRatio
                } else {
                    imgHeight = this.dimensions.marquage.height
                    imgWidth = imgHeight * imgRatio
                }

                this.$store.commit('editor/updateItemPosition', {
                    index: this.itemIndex,
                    x: this.dimensions.marquage.width / this.zoom / 2,
                    y: this.dimensions.marquage.height / this.zoom / 2,
                    w: imgWidth / this.zoom,
                    h: imgHeight / this.zoom,
                    pxByCm: this.dimensions.marquage.pxByCm,
                    rotation: 0
                })
                this.$store.dispatch('history/snapshot')
            },
            /**
             * create new image if an option changes
             */
            createImage() {
                this.loading = true
                const currentTechnique = this.$store.getters['marquage/currentTechnique'](this.item.marquage)

                const options = {...this.item.options}

                if(this.laserColor) {
                    options.dominantColor = this.laserColor
                }

                this.$store.dispatch('library/editImage', {
                    options: options,
                    cropOptions: this.item.cropOptions,
                    save: true,
                    nbColors: currentTechnique ? currentTechnique.nbColorIncluded : 1,
                    id: this.item.originalObj.id,
                })
                    .then((response) => {
                        this.$store.commit('editor/updateItemProperties', {
                            index: this.itemIndex,
                            properties: {
                                imageObj: {...response.obj}
                            }
                        })
                        this.loading = false
                    })
                    .catch((error) => {
                        this.loading = false
                    })
            }
        }
    }
</script>
