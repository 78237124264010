import * as htmlToImage from 'html-to-image'

export default {
    generateScreenshots(cb) {
        const screenshotsData = []
        const $screenshots = document.querySelectorAll('.screenshot')
        let count = 0
        for(const $el of $screenshots) {
            screenshotsData.push({
                label: $el.dataset.label,
                data: null
            })
            htmlToImage.toJpeg($el, { quality: 0.8 })
                .then(function (dataUrl) {
                    for(const sd of screenshotsData) {
                        if(sd.label == $el.dataset.label) {
                            sd.data = dataUrl
                        }
                    }
                    count++
                    if(count === $screenshots.length) {
                        cb(screenshotsData)
                    }
                })
                .catch(error => {
                    cb([])
                })
        }
    }
}