<template>
    <div class="grilletarif">
        <div class="grilletarif-head" v-if="!hideHeader">
            <div>
                <div class="grilletarif-title"><b>Grille tarifaire</b> (en &euro; HT)</div>
                <a href="#" class="link is-small" @click.prevent="openTarifsPopup">Voir tous les prix</a>
            </div>
            <div>
                <div class="price" v-if="prices.unit"><b>{{ totalQuantity }}</b> unités <span class="price-value">{{ prices.unit | price }}</span> / unité</div>
            </div>
        </div>
        <div class="grilletarif-scroll">
            <table class="grilletarif-table" v-if="grilleProduit">
                <thead>
                <tr>
                    <th>Quantité</th>
                    <th v-for="step in grilleProduit" :key="step.floor" class="is-price">{{ step.floor }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="afficheSansMarquage">
                    <td>Sans marquage</td>
                    <td v-for="(step, index) in grilleProduit" class="is-price">
                        <span class="grilletarif-table-price" :class="{ 'is-active' : index == activeColumnIndex }">{{ (step.price / 100) | price(false) }}</span>
                    </td>
                </tr>
                <tr v-for="grilleTechnique in grillesTechniques">
                    <td>
                        <b v-if="grilleTechnique.markingIncluded">Marquage inclus</b>
                        {{ grilleTechnique.label }}
                        <template v-if="showAll">
                        <span v-for="fraisFixe in grilleTechnique.fraisFixes" :key="fraisFixe.ref" class="grilletarif-frais">
                            {{ fraisFixe.label }} : <b>{{ (fraisFixe.unitPrice * fraisFixe.qty) / 100 }}€</b>
                        </span>
                        </template>
                    </td>
                    <td v-for="(step, index) in grilleTechnique.grille" class="is-price">
                    <span class="grilletarif-table-price" :class="{ 'is-active' : index == activeColumnIndex }">
                        <span v-if="step.price">{{ (step.price / 100) | price(false) }}</span>
                        <span v-else>-</span>
                    </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
    props: {
        hideHeader: Boolean,
        showAll: Boolean
    },
    computed: {
        ...mapGetters({
            prices: 'cart/prices',
            totalQuantity: 'variants/totalQuantity'
        }),
        ...mapState({
            marquages: state => state.marquage.items
        }),
        afficheSansMarquage() {
            // masque la premiere ligne "sans marquage" si on a uniquement des techniques avec marquage inclus
            const techniques = this.grillesTechniques
            let show = techniques.length === 0
            for(const tech of techniques) {
                if(!tech.markingIncluded) {
                    show = true
                }
            }
            return show
        },
        grilleProduit() {
            return this.$store.getters['variants/grilleTarifProduit']
        },
        hasMarkingIncluded() {
            return false
        },
        grillesTechniques() {
            const grilles = []
            const gp = this.grilleProduit

            for(const marquage of Object.values(this.marquages)) {
                for(const technique of Object.values(marquage.types)) {
                    for(const variant of technique.variants) {
                        if(marquage.config.markingTypeVariantId === variant.id || this.showAll) {
                            // on se base sur les quantités minimales de la grille produit
                            const variantGrille = []
                            for(const rowP of gp) {
                                let rowPrice = null
                                if (variant.markingIncluded) {
                                    if (variant.priceGrid.length) {
                                        for(const rowV of variant.priceGrid) {
                                            if(parseInt(rowV.floor) > parseInt(rowP.floor)) {
                                                break
                                            }
                                            rowPrice = parseInt(rowV.price) + parseInt(rowP.price)
                                        }
                                    } else {
                                        // if (parseInt(rowV.floor) > parseInt(rowP.floor)) {
                                        //     break
                                        // }
                                        rowPrice = rowP.price
                                    }
                                } else {
                                    for(const rowV of variant.priceGrid) {
                                        if(parseInt(rowV.floor) > parseInt(rowP.floor)) {
                                            break
                                        }
                                        rowPrice = rowV.price
                                    }
                                }
                                variantGrille.push({
                                    floor: rowP.floor,
                                    ceil: rowP.ceil,
                                    price: rowPrice
                                })
                            }
                            const label = variant.technicalLabel + ' ' + (variant.label ? variant.label + ' ' : '') + marquage.label
                            grilles.push({
                                label: label,
                                grille: variantGrille,
                                fraisFixes: variant.fraisFixes.filter(item => item.unitPrice != 0),
                                markingIncluded: variant.markingIncluded
                            })
                        }
                    }
                }
            }
            return grilles
        },
        activeColumnIndex() {
            const qty = this.totalQuantity
            let index = -1
            if (this.grilleProduit) {
                for (const i in this.grilleProduit) {
                    if (parseInt(this.grilleProduit[i].floor) <= qty) {
                        index = i
                    }
                }
            }
            return index
        }
    },
    methods: {
        openTarifsPopup() {
            this.$store.commit('popup/open', {
                slug: 'PopupGrilleTarifs'
            })
        }
    }
}
</script>
