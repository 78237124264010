import Constants from '../config/Constants'

export default {
    props: {
        item: Object,
        itemIndex: String,
        dimensions: Object,
        zoom: Number,
        pxByCm: Number,
        laserColor: String
    },
    mounted() {
        document.body.addEventListener('keydown', this._onKeyDown)
    },
    destroyed() {
        document.body.removeEventListener('keydown', this._onKeyDown)
    },
    computed: {
        /**
         * cannot drag or resize out of these bounds (marquage area)
         * @returns {{x: number, y: number, w: *, h: *}}
         */
        outerBounds() {
            return {
                x: this.dimensions.marquage.width / 2,
                y: this.dimensions.marquage.height / 2,
                w: this.dimensions.marquage.width,
                h: this.dimensions.marquage.height
            }
        },
        /**
         * get position and size multiplied by zoom
         */
        positionZoomed() {
            return {
                x: this.item.x * this.zoom,
                y: this.item.y * this.zoom,
                w: this.item.width * this.zoom,
                h: this.item.height * this.zoom
            }
        }
    },
    methods: {
        /**
         * drag, resize or rotate end, update position
         */
        updatePosition(updated) {
            this.$store.commit('editor/updateItemPosition', {
                index: this.itemIndex,
                x: updated.x / this.zoom,
                y: updated.y / this.zoom,
                w: updated.w / this.zoom,
                h: updated.h / this.zoom,
                pxByCm: this.dimensions.marquage.pxByCm,
                rotation: updated.angle
            })
            this.$store.dispatch('history/snapshot')
        },
        /**
         * select item, update store, witch unselect all other items
         */
        onSelect() {
            this.$store.commit('editor/selectItem', this.itemIndex)
        },
        /**
         * item unselected, update store
         */
        onDeselect() {
            this.$store.commit('editor/updateItemProperty', {
                index: this.itemIndex,
                name: 'selected',
                value: false
            })
        },
        /**
         * delete item
         */
        deleteItem() {
            this.$store.commit('editor/removeItem', this.itemIndex)
            this.$store.dispatch('history/snapshot')
        },
        /**
         * key down, check if we press delete key to delete item
         * @param event
         * @private
         */
        _onKeyDown(event) {
            if(event.code === 'Delete' && this.item.selected) {
                this.deleteItem()
            }
        }
    }
}
