<template>
    <drr
        :x="positionZoomed.x" :y="positionZoomed.y" :w="positionZoomed.w" :h="positionZoomed.h"
        :angle="item.rotation"
        :centerBound="item.rotation ? outerBounds : null"
        :outerBound="item.rotation ? null : outerBounds"
        :selected="item.selected"
        :deselectLocked="true"
        :aspect-ratio="true"
        :min-height="minHeight"
        @change="updatePosition"
        @select="onSelect"
        @deselect="onDeselect"
        @resizestart="onResizeStart"
        @resizestop="onResize"
        :custom-class="{ 'is-text': true }"
    >
        <div class="drr-inner" ref="inner">
            <canvas ref="textCanvas"></canvas>
        </div>
        <div class="drr-delete" @click.prevent="deleteItem">
            <svg-sprite icon="corbeille-white" width="17" height="17" />
        </div>
    </drr>
</template>

<script>
    import drr from './drr'
    import EditorItem from '../../mixins/EditorItem'
    import TextCanvas from '../../utils/TextCanvas'

    export default {
        name: 'EditorText',
        mixins: [EditorItem],
        components: {
            drr
        },
        data: () => ({
        }),
        mounted() {
            this.textCanvasContext = this.$refs.textCanvas.getContext('2d')
            if(this.item.x === null) {
                this._setDefaultPosition()
            }
            this.renderText()

            this._zoomBind = this.onZoom.bind(this)
            this.$parent.$on('zoomEnd', this._zoomBind)
        },
        destroyed() {
            this.$parent.$off('zoomEnd', this._zoomBind)
        },
        computed: {
            /**
             * minimal height of 2mm for texts
             */
            minHeight() {
                return Math.round((this.pxByCm / 5) * this.zoom)
            }
        },
        watch: {
            // item content or font family changed, update element width fo fit the item
            'item.content'() {
                this.renderText()
            },
            'item.fontFamily'() {
                this.renderText()
            },
            'item.bold'() {
                this.renderText()
            },
            'item.italic'() {
                this.renderText()
            },
            'item.color'() {
                this.renderText(false)
            },
            'item.lineHeight'() {
                this.renderText()
            },
            'item.align'() {
                this.renderText()
            },
            laserColor() {
                this.renderText(false)
            }
        },
        methods: {
            /**
             * set default position for the item when we add it, center it on marquage area
             * @private
             */
            _setDefaultPosition() {
                const height = Math.min(
                    this.dimensions.marquage.height / this.zoom / 2,
                    this.dimensions.marquage.width / this.zoom / 10
                )
                this.$store.commit('editor/updateItemPosition', {
                    index: this.itemIndex,
                    x: this.dimensions.marquage.width / this.zoom / 2,
                    y: this.dimensions.marquage.height / this.zoom / 2,
                    w: this.dimensions.marquage.width / this.zoom / 2,
                    h: this.item.fontSize ? this.item.fontSize : height,
                    pxByCm: this.dimensions.marquage.pxByCm,
                    rotation: 0
                })
                if(!this.item.fontSize) {
                    this.$store.commit('editor/updateItemProperty', {
                        index: this.itemIndex,
                        name: 'fontSize',
                        value: height
                    })
                }
                this.$store.dispatch('history/snapshot')
            },
            onResizeStart() {
                this.itemHeightRatio = this.item.height / this.item.fontSize
            },
            /**
             * resize element, adapt font size
             * @param rect
             */
            onResize(rect) {
                this.$store.commit('editor/updateItemProperty', {
                    index: this.itemIndex,
                    name: 'fontSize',
                    value: (rect.h / this.zoom) / this.itemHeightRatio
                })
                this.renderText()
            },
            /**
             * content has changed, render canvas text
             */
            renderText(updateDimensions = true) {
                const color = this.laserColor ? this.laserColor : this.item.color // laser color in technique override items color
                const measures = TextCanvas.draw(this.$refs.textCanvas, this.textCanvasContext, this.item.content, this.item.fontSize * this.zoom, this.item.fontFamily, this.item.align, this.item.lineHeight, color, this.item.bold, this.item.italic)

                if(updateDimensions) {
                    this.$store.commit('editor/updateItemProperty', {
                        index: this.itemIndex,
                        name: 'width',
                        value: Math.round(measures.width / this.zoom)
                    })
                    this.$store.commit('editor/updateItemProperty', {
                        index: this.itemIndex,
                        name: 'height',
                        value: Math.round(measures.height / this.zoom)
                    })
                }
            },
            onZoom() {
                this.renderText(false)
            }
        }
    }
</script>
