<template>
    <div>
        <div class="popup-html" v-html="$t('pao.messageHelp')" style="margin-bottom: 30px;"></div>
        <div
            class="uploadbox" :class="{ 'is-loading': loading, 'is-dragged': isDragged }"
            @dragover.prevent="dragHighlight" @drop.prevent="onEditorDrop($event)"
            @dragenter.self="dragHighlight" @dragleave.self="dragUnhighlight"
        >
            <div class="uploadbox-drop is-hidden-mobile">
                <svg-sprite classname="upload-drop-icon" width="60" height="60" icon="telecharger" />
                <div class="uploadbox-drop-text">{{ $t('library.drop') }}</div>
            </div>
            <div class="uploadbox-or is-hidden-mobile">
                <span>{{ $t('or') }}</span>
            </div>
            <button class="button uploadbox-button is-rounded is-green" @click.prevent="openUploadDialogue">{{ $t('library.browse') }}</button>
            <div class="uploadbox-help">
                <b style="font-size: 1.2em;">{{ $t('imageupload.extensionsBest') }} : EPS, AI et PDF</b><br>
                {{ $t('imageupload.extensionsAllowed') }} : JPG, PNG - {{ $t('imageupload.maxSize') }} : 2.5Mo<br>
                * {{ $t('imageupload.help') }}
            </div>
            <loader :absolute="true" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ScreenUpload',
        data() {
            return {
                loading: false,
                isDragged: false
            }
        },
        mounted() {
            // create a file input, used to open upload dialog
            this.fileSelector = document.createElement('input')
            this.fileSelector.setAttribute('type', 'file')
            this.fileSelector.setAttribute('accept', '.jpg, .png, .jpeg, .ai, .eps, .pdf')
            this.fileSelector.addEventListener('change', this.onFileChange)
        },
        destroyed() {
            this.fileSelector.removeEventListener('change', this.onFileChange)
        },
        methods: {
            openUploadDialogue() {
                this.fileSelector.click()
            },
            imageUpload(file) {
                this.loading = true

                this.$store.dispatch('library/uploadFile', {
                    file: file
                })
                    .then((response) => {
                        this.loading = false
                        this.$store.commit('popup/close')
                        setTimeout(() => {
                            this.$store.commit('popup/open', {
                                slug: 'PopupImageEdit',
                                params: {
                                    image: response,
                                    edit: false
                                }
                            })
                        }, 400)
                    })
                    .catch((error) => {
                        this.loading = false
                        this.$store.commit('notification/open', {
                            message: this.$t('apiError'),
                            error: true
                        })
                    })
            },
            /**
             * input file changed, upload file
             */
            onFileChange() {
                if(this.loading) {
                    return
                }
                if(this.fileSelector.files[0]) {
                    this.imageUpload(this.fileSelector.files[0])
                }
                this.fileSelector.value = ''
            },
            /**
             * drop image file(s) on editor, upload it
             */
            onEditorDrop(event) {
                this.dragUnhighlight()
                if (event.dataTransfer.items) {
                    // Use DataTransferItemList interface to access the file(s)
                    // If dropped items aren't files, reject them
                    if (event.dataTransfer.items[0] && event.dataTransfer.items[0].kind === 'file') {
                        const file = event.dataTransfer.items[0].getAsFile()
                        this.imageUpload(file)
                    }
                } else {
                    // Use DataTransfer interface to access the file(s)
                    if (event.dataTransfer.files[0]) {
                        this.imageUpload(event.dataTransfer.files[0])
                    }
                }
            },
            dragHighlight() {
                this.isDragged = true
                this.dragTimeout && clearTimeout(this.dragTimeout)
                this.dragTimeout = setTimeout(() => {
                    this.isDragged = false
                }, 500)
            },
            dragUnhighlight() {
                this.isDragged = false
            },
        }
    }
</script>

